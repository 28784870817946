import type { UserData } from '$libs/user';
import { writable, get } from 'svelte/store';
import { account } from '$stores/web3';
import { getUserReserves } from '$libs/service';
import { convertAaveUserDataToHanaData } from '$libs/user/helper';
import { marketData } from '.';

export const userData = writable<UserData>({
  address: '',
  totalSuppliedUSD: '0',
  totalCollateralUSD: '0',
  totalBorrowLimitUSD: '0',
  currentBorrowLimitUSD: '0',
  totalBorrowedUSD: '0',
  netSupplyApy: 0,
  netBorrowApy: 0,
  netApy: 0,
  healthFactor: '0',
  suppliedAssets: [],
  borrowedAssets: [],
  loanHealth: 0,
  ltv: '0',
  rewards: [],
});
export const userLoading = writable<boolean>(false);

export const fetchUserData = async () => {
  const _account = get(account);
  if (!_account || !_account.isConnected || !_account.address) return;

  userLoading.set(true);
  const data = await getUserReserves(_account.address as `0x${string}`);

  const _market = get(marketData);
  console.log('Hana User Data ----------', convertAaveUserDataToHanaData(data, _market));
  userData.update(() => convertAaveUserDataToHanaData(data, _market));
  userLoading.set(false);
};
