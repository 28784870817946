import { markets } from '$configs/market';
import type { AaveV3FormattedMarketReserves } from '../service/types';
import type { MarketData, MarketSummaryData } from './types';

export const checkIsValueNaNString = (value: string) => {
  return value === 'NaN' ? '0' : value;
};

export const convertAaveDataToHanaData = (data: AaveV3FormattedMarketReserves[]): MarketData[] => {
  const output: MarketData[] = [];

  Object.values(markets).forEach((_market) => {
    const _data = data.find((i) => i.underlyingAsset.toLowerCase() === _market.address?.toLowerCase());

    if (_data && _market.enabled) {
      output.push({
        ..._market,
        totalSupply: checkIsValueNaNString(_data.totalLiquidity),
        totalSupplyInUSD: checkIsValueNaNString(_data.totalLiquidityUSD),
        totalBorrow: checkIsValueNaNString(_data.totalVariableDebt),
        totalBorrowInUSD: checkIsValueNaNString(_data.totalVariableDebtUSD),
        supplyApy: checkIsValueNaNString(_data.supplyAPY),
        borrowApy: checkIsValueNaNString(_data.variableBorrowAPY),
        availableSupply: checkIsValueNaNString(_data.formattedAvailableLiquidity),
        availableSupplyUSD: checkIsValueNaNString(_data.availableLiquidityUSD),
        utilisationRate: checkIsValueNaNString(_data.supplyUsageRatio),
        priceInUSD: checkIsValueNaNString(_data.formattedPriceInMarketReferenceCurrency),
        borrowCap: checkIsValueNaNString(_data.borrowCap),
        borrowCapUSD: checkIsValueNaNString(_data.borrowCapUSD),
        supplyCap: checkIsValueNaNString(_data.supplyCap),
        supplyCapUSD: checkIsValueNaNString(_data.supplyCapUSD),
        maxLTV: checkIsValueNaNString(_data.formattedBaseLTVasCollateral),
        liquidationLTV: checkIsValueNaNString(_data.formattedReserveLiquidationThreshold),
        liquidationPenalty: checkIsValueNaNString(_data.formattedReserveLiquidationBonus),
        supplyIncentive: _data.aIncentivesData
          .filter((_incentive) => +_incentive.incentiveAPR !== Infinity && +_incentive.incentiveAPR !== 0)
          .map((_incentives) => {
            return {
              ..._incentives,
              incentiveAPR: String(+_incentives.incentiveAPR * 10 ** 12),
            };
          }),
        borrowIncentive: _data.vIncentivesData
          .filter((_incentive) => +_incentive.incentiveAPR !== Infinity && +_incentive.incentiveAPR !== 0)
          .map((_incentives) => {
            return {
              ..._incentives,
              incentiveAPR: String(+_incentives.incentiveAPR * 10 ** 12),
            };
          }),
      });
    }
  });

  return output;
};

export const convertHanaDataToHanaSummaryData = (data: MarketData[]): MarketSummaryData => {
  let totalSupply = 0;
  let totalBorrow = 0;

  data.forEach((_data) => {
    totalSupply += +_data.totalSupplyInUSD;
    totalBorrow += +_data.totalBorrowInUSD;
  });

  return {
    totalSupply,
    totalBorrow,
  };
};
