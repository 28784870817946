import { convertAaveDataToHanaData, convertHanaDataToHanaSummaryData } from '$libs/market/helper';
import type { MarketData, MarketSummaryData } from '$libs/market/types';
import { writable } from 'svelte/store';
import { getMarketReserves } from '$libs/service';

export const marketSummaryData = writable<MarketSummaryData>({
  totalBorrow: 0,
  totalSupply: 0,
});
export const marketData = writable<MarketData[]>([]);
export const marketLoading = writable<boolean>(false);

export const fetchMarketData = async () => {
  marketLoading.set(true);
  const data = await getMarketReserves();
  const processedData = convertAaveDataToHanaData(data);
  console.log('Hana Market ----------', processedData);
  marketData.update(() => processedData);
  marketSummaryData.update(() => convertHanaDataToHanaSummaryData(processedData));
  console.log('Hana Market Summary ----------', convertHanaDataToHanaSummaryData(processedData));
  marketLoading.set(false);
};
